
.view-project-contractor-container{
    margin: 1rem;
    padding: 0.5rem;
    border-top: 2px solid blue;
    box-shadow: 0px 0px 1px #777777;
    flex-grow: 1;
    background-color: white;
}

.view-project-contractor-container .title{
    display: grid;
    grid-template-columns: 5fr 1fr;
}

.view-project-contractor-container button{
    /* padding: 0.4rem; */
    border: 1px solid #e87722;
    background-color: #e87722;
    color: white;
    margin: 0.1rem;
    border-radius: 0.5rem;
}

.view-project-contractor-container button:active{
    background-color: #e8772299;
}

.view-project-contractor-container .show-employees{
    max-height: 0vh;
    overflow: hidden; 
    transition: 0.25s ease-out;
}
.view-project-contractor-container .show-employees.active{
    max-height: 70vh;
    overflow: auto;
    transition: 0.25 ease in;
}

.view-project-contractor-container .employees{
    display: flex;
    flex-wrap: wrap;
}


.view-project-contractor-container .employees .employee{
    flex-grow: 1;
    padding: 0.4rem;
    margin: 0.75rem;
    box-shadow: 0px 0px 1px #777777;
}

.view-project-contractor-container .employees .employee .contact-info{
    flex-grow: 1;
    padding: 0.4rem;
    margin: 0.75rem;
}