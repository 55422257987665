
.header-row{
    margin-left: 0;
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    background-color: #e8e8e8;
    height: 3rem;
    z-index: 5;
    box-shadow: 0 0.1rem 0.2rem #63666a;
    /* color: #e87722; */
}

.header-row .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    color: #e87722;
    height: 100%;
}

.header-row .logo img{
    height: 95%;
    margin: 0rem 0.6rem 0rem 0.6rem;
    border-radius: 1rem;
}

.header-row .nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #63666a;
    height: 100%;
    position: relative;
}
.header-row .nav-item:hover{
    flex: 1;
    color: #e87722;
    cursor: pointer;
}

.header-row .nav-item h5{
    margin: 0.25rem;
}

.account-menu{
    max-height: 0;
    width: 20vw;
    overflow: hidden;
    position: absolute;
    top: 90%;
    background-color: white;
    color: black;
    right: 50%;
}
.account-menu.active{
    border: 1px solid #63666a;
    max-height: 40vh;
    padding: 0.5rem 1rem;
}

.account-menu .option:hover{
    color: #e87722;
}

@media screen and (max-width: 480px) {
    .header-row strong{
        display: none
    }
    .account-menu{
        width: 50vw;
    };
}

@media screen and (max-width: 480px) {
    .header-row strong{
        display: none
    }
    .account-menu{
        width: 50vw;
    };
}


