
.view-project-anchor-training-container{
    margin: 0.5rem 0rem 0.5rem 0rem;
    padding: 1rem;
    box-shadow: 0px 0px 7px #777777;
    background-color: white;
}


.view-project-anchor-training-container .title{
    display: grid;
    grid-template-columns: 5fr 1fr;
}

.view-project-anchor-training-container button{
    border: 1px solid #e87722;
    background-color: #e87722;
    color: white;
    margin: 0.1rem;
    border-radius: 0.5rem;
}


.view-project-anchor-training-container button:active{
    background-color: #e8772299;
}

.view-project-anchor-training-container .show-anchor-training{
    max-height: 0vh;
    overflow: hidden; 
    transition: 0.25s ease-out;
}
.view-project-anchor-training-container .show-anchor-training.active{
    max-height: 70vh;
    overflow: auto;
    transition: 0.25 ease in;
}

