
.project-task-container{
    padding: 1rem;
    margin: 0.3rem;
    border: 1px solid #777777;
    box-shadow: 1px 1px 3px #777777;
    background-color: white;
}

.project-task-body{
    display: grid;
    grid-template-columns: 6fr 1fr;
    /* background-color: white; */
}

.project-task-line{
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
}