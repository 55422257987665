
.view-project-info-container{
    padding: 0.5rem;
    box-shadow: 0px 0px 7px #777777;
    background-color: white;
    margin: 0.25rem 0rem 0.25rem 0rem;
}

.view-project-info-container .date-line{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0.5rem;

}
.view-project-info-container .date-line .end-date{
    text-align: end;
}
.view-project-info-details{
    display: flex;
    flex-wrap: wrap;
}

.view-project-info-details .detail{
    padding: 0.25rem;
    flex-grow: 1;
}

.view-project-info-details .detail strong{
    text-decoration: underline;
}



