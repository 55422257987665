
.view-project-induction-container{
    margin: 0.5rem 0rem 0.5rem 0rem;
    padding: 1rem;
    box-shadow: 0px 0px 7px #777777;
    background-color: white;
}


.view-project-induction-container .title{
    display: grid;
    grid-template-columns: 5fr 1fr;
}

.view-project-induction-container button{
    /* padding: 0.4rem; */
    border: 1px solid #e87722;
    background-color: #e87722;
    color: white;
    margin: 0.1rem;
    border-radius: 0.5rem;
}


.view-project-induction-container button:active{
    background-color: #e8772299;
}

.view-project-induction-container .show-inductions{
    max-height: 0vh;
    overflow: hidden; 
    transition: 0.25s ease-out;
}
.view-project-induction-container .show-inductions.active{
    max-height: 70vh;
    overflow: auto;
    transition: 0.25 ease in;
}

.view-project-induction-container .employees{
    display: flex;
    flex-wrap: wrap;
}


.view-project-induction-container .employees .employee{
    flex-grow: 1;
    padding: 0.4rem;
    margin: 0.75rem;
    box-shadow: 0px 0px 1px #777777;
}