
.home-banner{
    border: 1px solid #e87722;
    margin: 1rem;
    padding: 0.75rem;
    display: flex;
    flex-wrap: wrap;
}

.home-banner .item{
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid black;
    flex-grow: 1;
    text-align: center;
    color: white;
    background-color: #63666a;
}

.home-banner .item:hover{
    color: white;
    cursor: pointer;
    background-color: #e87722;
}

.home-banner .item .icon{
    margin: 0.25rem;
}

.my-active-projects-container{
    border: 1px solid #e87722;
    margin: 1rem;
    padding: 0.75rem;
    max-height: 60vh;
    overflow-y: auto;
}


.my-active-projects-container button{
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    border: 1px solid #e87722;
    background-color: #e87722;
    color: white;
    margin: 0.2rem;
    border-radius: 0.2rem;
}

.my-active-projects-container button:hover{
    background-color: #e87722aa;
}
.my-active-projects-container button:hover:active{
    background-color: #e87722cc;
}
.my-active-projects-container button:focus{
    background-color: #e87722cc;
    box-shadow: 1px 1px 4px 2px #e87722cc;
    border: #e87722cc
}

.my-active-projects-container button:disabled{
    background-color: #e8772266;
    border-color: #e8772266;
}
.my-active-projects-container button:disabled:hover{
    background-color: #e8772266;
    border-color: #e8772266;
}
.my-active-projects-container button:disabled:hover:active{
    background-color: #e8772266;
    border-color: #e8772266;
}