
.task-maintenance-container{

    margin: 0.5rem;
    padding: 1rem;
    border: 1px solid #777777;
    box-shadow: 1px 1px 5px #777777;
}

.task-maintenance-container .body{
    display: grid;
    grid-template-columns: 4fr 1.5fr 1fr 1fr 0.5fr;
}

.task-maintenance-container .body div{
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.task-maintenance-container .task-types{
    max-height: 0vh;
    overflow: hidden;
    transition: 0.25s ease-out;
}

.task-maintenance-container .task-types.active{
    max-height: 70vh;
    transition: 0.25s ease-in;
    overflow: auto;
}