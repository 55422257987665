
.project-tasks-container{
    background-color: white;
    margin: 0.5rem 0rem 0.5rem 0rem;
    padding: 0.5rem;
}

.project-tasks{
    height: 55vh;
    margin: 0.25rem 0rem 0.25rem 0rem;
    overflow-y: auto;
    background-color: #77777733;
    /* overflow-x: hidden; */
    /* border: 1px solid #777777; */
}
.project-tasks .add-new{
    width: 99%;
}