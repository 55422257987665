

.company-display{
    padding: 1%;
}


.company-display button{
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    border: 1px solid #e87722;
    background-color: #e87722;
    color: white;
    margin: 0.2rem;
    border-radius: 0.2rem;
}

.company-display button:hover{
    background-color: #e87722aa;
}
.company-display button:hover:active{
    background-color: #e87722cc;
}
.company-display button:focus{
    background-color: #e87722cc;
    box-shadow: 1px 1px 4px 2px #e87722cc;
    border: #e87722cc
}

.company-display button:disabled{
    background-color: #e8772266;
    border-color: #e8772266;
}
.company-display button:disabled:hover{
    background-color: #e8772266;
    border-color: #e8772266;
}
.company-display button:disabled:hover:active{
    background-color: #e8772266;
    border-color: #e8772266;
}


.company-card{

    box-shadow: 1px 1px 3px grey;
    padding: 1rem;
    margin: 1%;
    background-color: white;
}

.component-card-title{

    display: grid;
    grid-template-columns: 6fr 1fr 1fr ;
    column-gap: 0.2rem;
    
}

.component-card-title div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.company-card-main{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.company-details{
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    background-color: #77777733;
}

.company-details.active{
    max-height: 80vh;
    transition: 0.5s ease;
    overflow: auto;
}

.company-details .website-line{
    background-color: white;
    padding: 0.5rem;
}

.head-office-container label{
    font-weight: bold;
}

.head-office-address{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.2rem;
    row-gap: 0.2rem;
}

.employee-details{
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    background-color: white;
}

.employee-details.active{
    max-height: 60vh;
    transition: 0.5s ease;
    overflow: auto;
}

.single-employee{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
}